import {useCallback} from 'react'

import {useLoggedInUser} from '@product-web/shared--user'
import {createPersistedState} from '@product-web/shared--web-platform/persisted-state'

import {sharedCashManagementBff} from '../bff-hooks'

const [useLocalStorage] = createPersistedState('cashManagementDesignSwitch', localStorage)

export function useCashManagementAdoptionState(): [boolean, (value: boolean) => void] {
    const {data} = sharedCashManagementBff.useCashManagementAdoptionState.getData.useQuery()
    const {id: userId, companyId} = useLoggedInUser()

    const [adoptionSwitchState, setAdoptionSwitchState] = useLocalStorage<'enabled' | 'disabled'>(
        `cashManagementAdoption_${companyId}_${userId}`,
        'enabled',
    )

    const setIsEnabled = useCallback(
        (state: boolean) => {
            setAdoptionSwitchState(state ? 'enabled' : 'disabled')

            window.location.href = state ? '/cash-management' : '/wallet'
        },
        [setAdoptionSwitchState],
    )

    const isEnabled = !!data?.hasCashManagementAdoption && adoptionSwitchState === 'enabled'

    return [isEnabled, setIsEnabled]
}
