import type {ReactNode} from 'react'
import {useEffect} from 'react'

import {tokens} from '@pleo-io/telescope'

import type {PageOrigin} from '@product-web/shared--paywall/index.bff'
import {pxBreakpoints} from '@product-web/shared--styles/theme'
import {useContainerQuery} from '@product-web/shared--telescope-lab/container-queries/container'

import {LandingPageKeyCards} from './components/landing-page-key-cards'
import {LandingPageMedia} from './components/landing-page-media'
import {LandingPageTop} from './components/landing-page-top'
import {LandingPagesConfig} from './landing-pages.helper'

import {trackLandingPagesEligibleViewed, trackUpgradeYourPlanViewed} from '../lib/tracking'

export interface LandingPagesProps {
    pageOrigin: PageOrigin
    isOnTrial?: boolean
    topAction?: ReactNode
    entitledCTA?: ReactNode
}

export const LandingPages = ({
    pageOrigin,
    isOnTrial,
    topAction,
    entitledCTA,
}: LandingPagesProps) => {
    const landingPageConfig = LandingPagesConfig[pageOrigin]

    const isDesktop = useContainerQuery({
        name: 'main',
        minWidth: pxBreakpoints.tabletMedUp,
    })

    useEffect(() => {
        if (landingPageConfig) {
            if (entitledCTA) {
                trackLandingPagesEligibleViewed({page: pageOrigin})
            } else {
                trackUpgradeYourPlanViewed({page: pageOrigin})
            }
        }
    }, [landingPageConfig, entitledCTA, pageOrigin])

    if (!landingPageConfig) {
        return null
    }

    return (
        <div
            data-testid="landing-pages"
            css={
                isDesktop
                    ? {
                          border: tokens.borderPrimary,
                          borderRadius: tokens.arc20,
                          padding: tokens.spacing40,
                          marginBottom: tokens.spacing40,
                          width: '100%',
                      }
                    : null
            }
        >
            <LandingPageTop
                pageOrigin={pageOrigin}
                planUpgradeSource={landingPageConfig.planUpdateSource}
                entitledCTA={entitledCTA}
                title={landingPageConfig.getTitle()}
                subtitle={landingPageConfig.getSubtitle?.()}
                isOnTrial={isOnTrial}
                topAction={topAction}
            />

            <>
                <div css={{marginTop: tokens.spacing32, marginBottom: tokens.spacing40}}>
                    <LandingPageMedia pageOrigin={pageOrigin} />
                </div>

                <LandingPageKeyCards cards={landingPageConfig.cards} />

                {landingPageConfig.bottomAction && (
                    <>
                        <hr
                            css={{
                                borderTop: tokens.borderPrimary,
                                borderBottom: 0,
                                margin: `${tokens.spacing40} 0`,
                            }}
                        />

                        {landingPageConfig.bottomAction}
                    </>
                )}
            </>
        </div>
    )
}
